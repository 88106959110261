.date-modal .modal-body {
  display: flex;
  flex-direction: column;
  padding: 50px;
}

.date-modal .subtitle {
  font-size: 20px;
  margin: 20px;
  text-align: center;
  font-weight: 600;
}

.date-modal .turn-info {
  border-bottom: 1px solid black;
  padding-bottom: 50px;
  margin-top: 20px;
  font-size: 16px;
}

.date-modal .info-name {
  font-weight: 700;
  margin-right: 10px;
}