.preview-calendar .date-enabled {
  background-color: #08ad08;
  color: white;
}
.preview-calendar .date-enabled:hover {
  background-color: #2ccc2c !important;
}

.preview-calendar .date-disabled {
  background-color: #d10000;
  color: white;
}
.preview-calendar .date-disabled:hover {
  background-color: #f33232 !important;
}

.preview-calendar .date-both {
  background: linear-gradient(180deg, #08ad08 50%, #d10000 50%);
  color: white;
}

.preview-calendar .date-disabled:hover {
  background-color: linear-gradient(180deg, #2ccc2c 50%, #f33232 50%) !important;
}

.square{
  width: 20px;
  height: 20px;
}

.turn-data {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.turn-data span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  width: 200px;
}

.turn-info {
  display: flex;
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-color: #0477AD;
  padding: 15px 0px;
}

.turn-info-values {
  display: flex;
  padding: 15px 0px;
}