.header {
  min-height: 78px;
  background-color: #ffffff;

  display: flex;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-text{
  color: var(--main-color);
  font-size: 15px;
}

.header-logo {
  width: 33.33333333%;
  display: flex;
  align-items: center;
  padding-left: 29px;
}

.header-title {
  width: 33.33333333%;
  font-size: calc(10px + 2vmin);
  font-weight: 700;
  color: var(--main-color);
  text-align: center;
  align-self: center;
}

.header-space {
  width: 33.33333333%;
}


.header-admin {
  width: 100%;
  text-align: end;
  align-self: center;
}

.header-admin-container {
  color: var(--main-color);
  padding-right: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-line {
  border: 1px solid var(--main-color);
  height: 40px;
  margin: 0px 30px;
}

.header-admin-text {
  font-size: 16px;
  padding-left: 10px;
}

.header-username {
  font-size: 16px;
  padding-left: 10px;
  color: var(--main-color);
}

.header-title {
  width: 33.33333333%;
  font-size: calc(10px + 2vmin);
  font-weight: 700;
  color: var(--main-color);
  text-align: center;
  align-self: center;
}

.header-left {
  width: 33.33333333%;
}

.logout {
  padding-right: 30px;
}

@media (max-width:300px) {
  .header-logo img {
    display: none;
  }

  .header-logo {
    margin: 0px !important;
  }
}


@media (max-width: 925px) {
  .header {
    flex-direction: column;
    justify-content: space-evenly;
  }

  .header-logo {
    margin: 20px;
  }

  .logout {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-right: 0 !important;
  }

  .logout button {
    margin: 10px;
  }

  .admin-line {
    display: none !important;
  }

  .header-admin {
    margin: 20px;
  }
}