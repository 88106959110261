.admin-container {
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 5%;
}

.admin-tags {
  font-size: 14px !important;
  padding: 8px !important;
}

.ant-table-content {
  overflow: auto !important;
}

.admin-container input:not(input[type=radio]) {
  height: 50px;
  border-color: #687073 !important;
}

input[type=date] {
  height: 50px;
  border-color: #687073 !important;
}

.admin-container select {
  height: 50px;
  border-color: #687073 !important;
}

/* ----------------- */

.ant-pagination-item-link {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}


.weektable-th {
  font-weight: 700; 
  font-size: 17px;
}

.weektable-activity {
  font-weight: 700; 
  font-size: 17px;
  color: #2590EB;
}

.weektable-day {
  font-weight: 600; 
  font-size: 16px;
  color: gray;
}