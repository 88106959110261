@import "./Calendar.css";

:root {
  --main-color: #0477AD;
}

.card-calendar {
  overflow: auto;
}

.loading-spinner {
  font-size: 40px;
  width: 80px;
  height: 80px;
  color: #A5DD67
}

.loading-modal .modal-content {
  box-shadow: 1px 7px 10px 1px rgba(210, 210, 210, 0.52);
  border: 1px solid #687073;
  border-radius: 10px !important;
}

.fw-700{
 font-weight: 700;
}

.f-color{
  color: var(--main-color);
}