
.modalTitle {
  font-size: 30px !important;
}

.modal-success-text {
  font-size: 22px !important;
}

.login-icons {
  position: relative;
  bottom: 35px;
  width: 100%;
  left: 90%;
  font-size: 22px;
}

.login-seccion {
  width: 80%;
  max-width: 400px;
  margin-top: 30px;
}

.login-seccion input {
  height: 50px;
}

.admin-select {
  z-index: 2;
  font-size: 20px !important;
}