/* .form-container {
  padding: calc(20px + 2vmin) 0px;
  padding-left: 20px;
  padding-right: 20px;
} */

/* .form {
} */

/* // */

.step-form {
  margin-top: 3rem;
  min-height: 500px;
  padding: 30px;
}

.step-form .forms {
  margin-top: 40px;
}

.form-files {
  flex-wrap: wrap;
}

/* // */

.file-upload {
  color: #92adc5;
  font-weight: normal;
  border: 2px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 38px;
  border-radius: 6px;
}

.file {
  opacity: 0;
  position: absolute;
  width: 120px;
  height: 48px;
  font-size: 0;
  cursor: pointer;
}

.file-upload:hover {
  background-position: 0 -100%;
  color: #2590eb;
}

.form-group {
  margin-bottom: 1rem;
}

.form-subtitle {
  font-size: 20px;
  color: var(--main-color);
  font-weight: 600;
}

.horary {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.date-picker {
  height: 38px;

  font-weight: 400;

  color: #212529;
  background-color: #fff;

  border-radius: 0.375rem;
}

.react-date-picker__wrapper {
  border: 1px solid #ced4da !important;
  border-radius: 0.375rem;
  padding: 0 0.75rem;
}

.react-date-picker__inputGroup__leadingZero {
  padding-bottom: 1px;
  padding-left: 1px;
}

.text-red {
  color: red;
}

.text-black {
  color: black;
}

.react-calendar {
  min-width: 300px;
  width: 100% !important;
}

/* ------------------- STEPS --------------- */

/* .steps-content {
  min-height: 200px;
  margin-top: 16px;
  padding-top: 80px;
  text-align: center;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
} */

.steps-action {
  margin-bottom: 50px;
  text-align: center;
}

.ant-steps-icon {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

/* ----------------------------------------- */

.hidden {
  display: none;
}

/* ----------------------------------------- */

.form-container .form-title {
  text-align: center;
  color: #0477ad;
  font-weight: 800;
  font-size: 20px;
  padding: 20px;
  margin-bottom: 40px;
}

.form label {
  font-weight: 600;
}

.form input:not(input[type="radio"]) {
  height: 50px;
  border-color: #687073 !important;
}

.form .row {
  margin-bottom: 20px !important;
}

.form .row .col {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

/* ----------------------------------------- */

.form .next-button {
  background: #0477ad !important;
  border-color: #0477ad !important;
  border-radius: 8px !important;
  color: white;
  /* padding: 15px; */

  width: 110px;
  height: 50px;
  margin: 10px !important;
}

.form .prev-button {
  background: white !important;
  border-color: #0477ad !important;
  border-radius: 8px !important;
  color: #0477ad;
  /* padding: 15px; */

  width: 110px;
  height: 50px;
  margin: 10px !important;
}

/* ----------------------------------------- */

.radio-inputs {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
}

.form .radio-input {
  display: flex !important;
  align-items: center;
  margin-bottom: 10px;

  margin-top: 28px;
  margin-right: 28px;
}

.form .radio-input input {
  height: 28px;
  width: 28px;
  margin-right: 8px;

  margin-top: 0 !important;
  margin-left: 0 !important;
}

.form .radio-input input[type="radio"] {
  border: 3px solid #0477ad !important;
}

.form .radio-input input:checked[type="radio"] {
  background-color: #0477ad !important;
}

.form .radio-input input:checked[type="radio"] {
  background-image: url("https://staticcontent.sannicolasciudad.gob.ar/images/icons/Check.png") !important;
  background-size: auto;
}

/* ----------------------------------------- */

.invalid-feedback {
  font-size: 14px !important;
}

/* ----------------------------------------- */

/* ------ */

.ant-steps-item-title {
  padding-right: 0 !important;
}

/* ------ */

.ant-steps-item-tail {
  top: 15px !important;
}

.ant-steps-item-tail:after {
  height: 4px !important;
  background: #c5e0ff !important;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail:after {
  background: #0477ad !important;
}

.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 66px !important;
  padding: 3.5px 16px !important;
}

/* ------ */

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #0477ad !important;
  background: #0477ad !important;
}

.ant-steps-item-icon {
  width: 40px !important;
  height: 40px !important;
  margin-right: 0 !important;
}

.ant-steps-item-icon .ant-steps-icon {
  top: 0 !important;
}

.ant-steps-icon {
  border-color: #0477ad !important;
  font-weight: 600;
  font-size: 20px !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: white !important;
}

/* ------ */

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #c5e0ff !important;
  border: 2px solid #0477ad !important;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon
  .ant-steps-icon {
  color: #0477ad !important;
}

/* ------ */

.ant-steps-item-icon {
  background: #c5e0ff !important;
}

.ant-steps-item-wait .ant-steps-item-icon {
  border: none !important;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #0477ad !important;
}

/* ------ */

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 0 !important;
}

/* ------ */

.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #c5e0ff !important;
}

/* ----------------------------------------- */

.change-activity .rmdp-wrapper {
  width: 100%;
  min-width: 400px;
  max-width: 600px;
}

.change-activity .rmdp-calendar {
  width: 100%;
}

.change-activity .rmdp-day-picker div {
  width: 100%;
}

.change-activity .rmdp-day-picker .rmdp-day {
  width: 40px !important;
  height: 40px !important;
}
