.nuevos-turnos{
  padding: 50px;
}

.nuevos-turnos .rmdp-header-values{
  font-size: 16px !important;
}

.nuevos-turnos .rmdp-week-day{
  height: 50px !important;
  width: 50px !important;
}

.nuevos-turnos .rmdp-week-day{
  font-size: 16px !important;
}

.nuevos-turnos .rmdp-day{
  height: 50px !important;
  width: 50px !important;
}

.nuevos-turnos .rmdp-day span{
  font-size: 16px !important;
}

.label {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}