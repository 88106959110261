.estado-confirmado {
  color: green !important;
  border-color: green !important;
}
.estado-pendiente {
  color: #a4a4a4 !important;
  border-color: #a4a4a4 !important;
}
.estado-cancelado {
  color: red !important;
  border-color: red !important;
}